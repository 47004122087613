<template>
    <!-- Table Container Card -->
    <b-card
        no-body
    >
        <app-collapse>
            <app-collapse-item title="Filtros">
                <b-card no-body class="fixed">
                    <div class="m-2">
                        <!-- Filtros -->
                        <b-row align-h="center" class="mt-2">
                            <b-col lg="3" md="3" sm="12">
                                <b-form-group
                                    label="Fecha inicial"
                                >
                                    <flat-pickr
                                        class="form-control d-inline-block ml-50 mr-1"
                                        :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                                        v-model="startDate"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <b-form-group
                                    label="Fecha final"
                                >
                                    <flat-pickr
                                        class="form-control d-inline-block ml-50 mr-1"
                                        :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                                        v-model="endDate"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <b-form-group
                                    label="Usario que recibio"
                                >
                                    <v-select
                                        v-model="responsibleUserId"
                                        label="name"
                                        :options="users"
                                        class="select-size-m"
                                        :reduce="option => option.userId"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <b-form-group
                                    label="Tipo de registro"
                                >
                                    <v-select
                                        v-model="recordType"
                                        label="name"
                                        :options="recordTypes"
                                        class="select-size-m"
                                        :reduce="option => option.name"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row align-h="center" class="mt-2">
                            <b-col lg="4" md="4" sm="12">
                                <b-form-group
                                    label="Registros por página"
                                    label-for="per-page"
                                >
                                    <v-select
                                        id="per-page"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        :clearable="false"
                                        class="per-page-selector d-inline-block ml-50 mr-1"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="4" sm="12">
                                <b-button
                                    class="mt-1 mb-1"
                                    variant="secondary"
                                    @click="findFinancialTransactionsByFinancialTransactionsFiltersApi()"
                                    :disabled=disabledButton
                                >
                                    <feather-icon
                                        icon="SearchIcon"
                                        class="mr-50"
                                    />
                                    Buscar registros
                                </b-button>
                            </b-col>
                            <b-col lg="4" md="4" sm="12">
                                <b-button
                                    class="mt-1 mb-1"
                                    variant="primary"
                                    @click="showModal('createFinancialTransactionDialog')"
                                >
                                    <feather-icon
                                        icon="SaveIcon"
                                        class="mr-50"
                                    />
                                    Crear registro
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </app-collapse-item>
        </app-collapse>
        <b-overlay
            :show="tableOverlay"
            rounded="sm"
        >
            <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="financialTransactions"
                @row-clicked="onRowClicked"
                ref="refInvoiceListTable"
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No hay registros"
                class="position-relative"
            >
                <template #cell(createdAt)="data"> {{ new Date(data.item.createdAt) | moment("DD/MM/YYYY, h:mm a") }} </template>
                <template #cell(modifiedAt)="data"> {{ new Date(data.item.modifiedAt) | moment("DD/MM/YYYY, h:mm a") }} </template>
                <template #cell(amount)="data"> ${{ data.value }} </template>
            </b-table>
        </b-overlay>
        <br>

        <div class="mx-2 mb-2">
            <b-row>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
                </b-col>
                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>

            <b-modal
                id="createFinancialTransactionDialog"
                title="Ingrese los datos del ingreso/gasto"
                @ok-title="!isUpdateEnabled ? 'Registrar movimiento' : 'Actualizar registro'"
                cancel-title="Cerrar"
                cancel-variant="outline-secondary"
                @ok="!isUpdateEnabled  ? createFinancialTransactionApi() : changeFinancialTransactionApi()"
                @cancel="cleanFields()"
                centered
            >
                <b-form-group
                    label="Tipo de registo"
                    label-for="type"
                >
                    <v-select
                        v-model="financialTransaction.type"
                        label="name"
                        :options="recordTypes"
                        class="select-size-m"
                        :reduce="option => option.name"
                    />
                </b-form-group>
                <b-form-group
                    label="Concepto"
                    label-for="concept"
                >
                    <v-select
                        v-model="financialTransaction.transactionType"
                        label="name"
                        :options="financialTransaction.type === 'Gasto' ? expenseTypes : incomesTypes"
                        class="select-size-m"
                        :reduce="option => option.name"
                    />
                </b-form-group>
                <b-form-group
                    label="Usuario"
                    label-for="concept"
                >
                    <v-select
                        v-model="financialTransaction.responsibleUserId"
                        label="name"
                        :options="users"
                        class="select-size-m"
                        :reduce="option => option.userId"
                    />
                </b-form-group>
                <b-form-group
                    label="Metodo de pago"
                    label-for="concept"
                >
                    <v-select
                        v-model="financialTransaction.paymentMethod"
                        label="name"
                        :options="paymentMethods"
                        class="select-size-m"
                        :reduce="option => option.name"
                    />
                </b-form-group>
                <b-form-group
                    label="Monto"
                    label-for="reason"
                >
                    <b-form-input
                        id="reason"
                        type="number"
                        v-model="financialTransaction.amount"
                        trim
                        placeholder="100"
                    />
                </b-form-group>
                <b-form-group
                    label="Razon"
                    label-for="reason"
                >
                    <b-form-input
                        id="reason"
                        v-model="financialTransaction.reason"
                        trim
                        placeholder="Explicar motivo"
                    />
                </b-form-group>
                {{ isUpdateEnabled ? 'Autorizo: '+financialTransaction.userName : "" }}

            </b-modal>
        </div>
    </b-card>

</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker';

.per-page-selector {
  width: 90px;
}

.fixed {
  //position: sticky;
  //top: 5.5em;
  //z-index: 20;
  //margin-bottom: 0;
  //border-bottom: 3px solid #7367f0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BPagination,
  BButton,
  BOverlay,
  BModal,
  BImg,
  BTabs,
  BTab,
  BBadge,
  BSpinner,
    BEmbed
} from 'bootstrap-vue';
import Empty from '../../components/empty-page/Empty.vue'
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
flatpickr.localize(Spanish);

export default {
  components: {
      AppCollapseItem, AppCollapse,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    flatPickr,
    BPagination,
    BButton,
    BOverlay,
    BModal,
    BImg,
    BTabs,
    BTab,
    BBadge,
    BSpinner,
    Empty,
      BEmbed
  },
  data() {
    return {
      // Table
      companies: [],
      tableColumns: [
        { key: 'shortFinancialTransactionId', label: 'ID', sortable: true, thStyle: { width: '1em' } },
        { key: 'createdAt', label: 'Fecha de creación', sortable: true },
        { key: 'type', label: 'Tipo', sortable: true },
        { key: 'transactionType', label: 'Concepto', sortable: false },
        { key: 'amount', label: 'Monto', sortable: true },
        { key: 'responsibleUserName', label: 'Quien pidio', sortable: true },
        { key: 'userName', label: 'Quien autorizo', sortable: true },
      ],
      sortBy: 'creationDate',
      sortDesc: true,
      disabledButton: true,
        isUpdateEnabled: false,

      // Data
      user: JSON.parse(localStorage.getItem("user")),
      financialTransactions: [],

      users: [],
        recordTypes: [
            { name: 'Gasto', },
            { name: 'Ingreso', },
        ],
        expenseTypes: [
            { name: 'Bascula Publica', },
            { name: 'Gasolina', },
            { name: 'Mecanico/Vulca', },
            { name: 'Infracciones', },
            { name: 'Viaticos', },
            { name: 'Prestamos', },
            { name: 'Deposito Cta. de Cheques', },
            { name: 'Otros gastos', },
            { name: 'Devolucion Ventas', },
        ],
        incomesTypes: [
            { name: 'Prestamo', },
            { name: 'Ingreso 2', },
        ],
        paymentMethods: [
            { name: 'Efectivo', },
            { name: 'Tarjeta', },
            { name: 'Transferencia', },
        ],

      responsibleUserId: undefined,
      recordType: undefined,
      startDate: this.$moment().startOf('day').format('DD-MM-YYYY HH:mm'),
      endDate: this.$moment().endOf('day').format('DD-MM-YYYY HH:mm'),
     financialTransaction: {
         transactionType: undefined,
         type: 'Gasto',
         paymentMethod: 'Efectivo',
         companyId: undefined,
         reason: "",
         userId: undefined,
         responsibleUserId: undefined,
         amount: 0,
     },

      perPage: 5,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      tableOverlay: true,

      // Filters
      generalFilters: {},
      dateFilters: {},
      statusFilters: {},
      shouldFilter: {},
    };
  },
  async created() {
    this.findPosUsersByCompanyApi()
    this.findFinancialTransactionsByFinancialTransactionsFiltersApi()

  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.refresh);
    next();
  },
  methods: {
      cleanFields(){
          this.isUpdateEnabled = false
          this.financialTransaction = {
              transactionType: undefined,
                  type: 'Gasto',
                  paymentMethod: 'Efectivo',
                  companyId: undefined,
                  reason: "",
                  userId: undefined,
                  responsibleUserId: undefined,
                  amount: 0,
          }
      },
    createFinancialTransactionApi(){
        this.financialTransaction.companyId = this.user.companyId
        this.financialTransaction.userId = this.user.userId

        this.$axios.post(this.$services + 'manager/createFinancialTransactionApi', {
            ...this.financialTransaction
        }).then(res => {
            this.showToast("success", String("¡Registro creado con éxito!"));
        }).catch(error => {
            this.showToast("danger", String("Error al crear"));
        }).finally(() => {
            this.findFinancialTransactionsByFinancialTransactionsFiltersApi()
            this.cleanFields()
        });

    },
      changeFinancialTransactionApi(){
          this.financialTransaction.companyId = this.user.companyId
          this.financialTransaction.userId = this.user.userId

          this.$axios.post(this.$services + 'manager/changeFinancialTransactionApi', {
              ...this.financialTransaction
          }).then(res => {
              this.showToast("success", String("¡Registro actualizado con éxito!"));
          }).catch(error => {
              this.showToast("danger", String("Error al actualizar"));
          }).finally(() => {
              this.findFinancialTransactionsByFinancialTransactionsFiltersApi()
              this.cleanFields()
          });
      },
    onRowClicked(financialTransaction) {
        this.isUpdateEnabled = true;
          this.financialTransaction = financialTransaction
        this.showModal('createFinancialTransactionDialog')
    },
    findFinancialTransactionsByFinancialTransactionsFiltersApi() {
        this.tableOverlay = true
        this.disabledButton = true;
        this.$axios.post(this.$services + 'manager/findFinancialTransactionsByFinancialTransactionsFiltersApi', {
            responsibleUserId: this.responsibleUserId,
            type: this.recordType,
            companyId: this.user.companyId,
            startDate: this.$moment(this.startDate, 'DD-MM-YYYY HH:mm').utc().format(),
            endDate: this.$moment(this.endDate, 'DD-MM-YYYY HH:mm').utc().format(),
        }).then(res => {
            if (res.data) {

                this.financialTransactions = res.data
                this.totalRows = res.data.length;

            }
        }).finally(() => {
            this.disabledButton = false;
            this.tableOverlay = false
        });
    },
  showModal(modalName) {
      this.$bvModal.show(modalName);
  },
  hideModal(modalName) {
      this.$bvModal.hide(modalName);
  },
    formatNumber(value) {
      const number = this.$BigNumber(value);
      return number.toFormat(0, this.$BigNumber.ROUND_DOWN);
    },
    findPosUsersByCompanyApi() {
        this.$axios
            .post(this.$services + 'manager/findPosUsersByCompanyApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.users = res.data;
            })
    },
    findPosByCompanyIdApi() {
        this.$axios
            .post(this.$services + 'manager/findPosByCompanyIdApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.pointOfSales = res.data;
            })
    },
      showToast(variant, text) {
          this.$toast({
              component: ToastificationContent,
              props: {
                  title: "Notificación",
                  icon: "BellIcon",
                  text,
                  variant,
              },
          });
      },
  },
};
</script>
